body{
  margin: 0;
  padding: 0;
}
.signup-container{
  overflow: hidden;
  max-height: 100vh;
}
.single-response{
  background-image: url('../src/assets/img/bg-logo.png');
}
.btn-gradient{
  background: linear-gradient(268.47deg, #26066E -5.3%, #F72585 103.74%, rgba(38, 6, 110, 0) 106.06%);
  transition: .5s;

}
.bg-gradient{
  background: linear-gradient(268.47deg, #26066E -5.3%, #F72585 103.74%, rgba(38, 6, 110, 0) 106.06%);

}
.btn-gradient:hover{
  /* background: #26066E!important; */
  background: linear-gradient(268.47deg, #F72585 -5.3%, #26066E 103.74%, rgba(38, 6, 110, 0) 106.06%);

  color: white;
}
.bg-kaffirbtn:hover{
  background-color: #26066E;
  color: white;
}
.bg-kaffirbtn2:hover{
  background-color: white;
  color: #F72585;
}
.bg-kaffirbtn2{
  background-color:#F72585;
  color: white;
}

a{
  text-decoration: none!important;
}
.maxWidth{
  max-width: 1300px;
  width: 85%;
  margin: auto;
}
.maxWidth-lg{
  max-width: 1900px;
  width: 100%;
  margin: auto;
}
/* pagination */
.pagination{
  width: 50%;
    margin: 10px auto;
    justify-content: center;
    border-radius: 10px;
    padding: 5px;
    display: flex;
}
button{
  outline: none!important;
}
.pagination li{
  padding: 5px 10px;
  border: 1px solid #F72585;
  border-radius: 3px;
  margin: 2px;
  text-decoration: none;

  box-shadow: 0px 0px 3px rgb(244, 109, 158);
  
}
.pagination .active{
  background-color: #F72585;
  color: white;
}
.pagination .active a{
  color: white;
  text-decoration: none;
}
.pagination .disabled{
  opacity: .4;
}
.pagination li a{
  color: #F72585;
  text-decoration: none;

}
.pagination:nth-child(2){
  display: none!important;
}
/* animations */

.card-jump{
  animation-name:jump;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-direction: alternate-reverse;
}
.welcome-box::-webkit-scrollbar{
  display: none;
}
.no-line:focus{
  height: 500px;
  outline-color: #F72585;
  outline: none!important;
  border: none;
}
input:focus, textarea:focus, select:focus{
  outline:0px !important;
  -webkit-appearance:none;
  box-shadow: none !important;
}
@keyframes jump {
  from {margin-top: -10px;}
  to {margin-top: initial;}
  
}
.card-jump-2{
  animation-name:jumps;
  animation-duration:1s;
  animation-iteration-count:infinite;
  animation-direction: alternate-reverse;
}
@keyframes jumps {
 
  from {margin-top: -15px;}
  to {margin-top: initial;}
}
@media only screen and (max-width:900px) {
  .maxWidth{
    width: 95%;
  } 
  .heroImg{
    top: 45px;
  }
}
@media only screen and (max-width:1385px) {

  .hero-rect{
    left:-3%;
  }
}
@media only screen and (max-width:1285px) {

  .hero-rect{
    display: none;
  }
}
@media only screen and (max-width:767px) {
  .hero-img{
   display: none;
  }
  .hide{
    display: none;
  }
  .card-jump-2{
    animation-name: noAnimate;
  }
  .card-jump{
    animation-name: noAnimate;
  }
  .hide-lg{
    display: none;
   }
}